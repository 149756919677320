<template>
  <div class="mt-4">
    <div
      class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-1 text-center">Add Employee</h1>
      </div>
    </div>
    <div class="flex justify-center">
      <div class=" sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
        <div class=" card rounded-xl bg-white p-4 my-4">
          <div class="sm:grid grid-cols-2 gap-4 mt-2">
            <div class="mb-4">
                <TextField
                :inputId="'firstName'"
                :textMaxlength="50"
                :showcharLimit="true"
                :inputext="sendObj.firstName"
                :placholderText="`First Name`"
                :lableText="'First Name'"
                :autoFocus="false"
                @keyPressAction="errObj.firstNameErr = ''"
                :fieldError="errObj.firstNameErr !== ''"
                @blurAction="sendObj.firstName.trim() !== '' ? errObj.firstNameErr ='' : null "
                @inputChangeAction="(data) => sendObj.firstName = data"
                />
                <p v-if="errObj.firstNameErr !== ''" class="text-error heading-7">{{errObj.firstNameErr}}</p>
            </div>
            <div class="mb-4">
                <TextField
                :inputId="'lastName'"
                :textMaxlength="50"
                :showcharLimit="true"
                :inputext="sendObj.lastName"
                :placholderText="`Last Name`"
                :lableText="'Last Name'"
                :autoFocus="false"
                @inputChangeAction="(data) => sendObj.lastName = data"
                />
            </div>
          </div>
          <!-- <p class="label_css -mb-2">Cell Number</p>
          <div class="sm:grid grid-cols-12 gap-4 mt-2">
            <div class="mb-4 col-span-1">
              <NumberInput
               :inputId="'cellNumberPre'"
               :inputext="sendObj.cellNumberPrefix"
               :placholderText="``"
               :inputType="'text'"
               :textRight="false"
               :autoFocus="false"
               @inputChangeAction="(data) => sendObj.cellNumberPrefix = data" />
            </div>
            <div class="mb-4 col-span-5">
              <NumberInput
                :inputId="'cellNumberVal'"
                :inputext="sendObj.cellNumber"
                :placholderText="`Cell Number`"
                :inputType="'text'"
                :textRight="false"
                :textMaxlength="12"
                :autoFocus="false"
                @blurAction="checkContactNumber()"
                @inputChangeAction="(data) => sendObj.cellNumber = data" 
                @keyPressAction="errObj.phoneErr = ''"
                :fieldError="errObj.phoneErr !== ''" />
               <p class="text-error heading-7">{{errObj.phoneErr}}</p>
            </div>
          </div> -->
          <div class="sm:grid grid-cols-2 gap-4 mt-2">
            <div class="mb-4">
              <TextField
              :inputId="'Usernameinput'"
              :showcharLimit="false"
              :inputext="sendObj.userName"
              :placholderText="`Username`"
              :lableText="'Username'"
              @keyup="checkuserName"
              @blurAction="verifyUserName"
              :autoFocus="false"
              :fieldError="errObj.userNameErr !== ''"
              @inputChangeAction="(data) => sendObj.userName = data"
              />
              <p class="text-error heading-7">{{errObj.userNameErr}}</p>
            </div>
            </div>
          <div class="sm:grid grid-cols-2 gap-4 mt-2">
            <div class="mb-4">
              <TextField
              :inputId="'email'"
              :textMaxlength="250"
              :showcharLimit="true"
              :inputext="sendObj.emailAddress"
              :placholderText="`Email`"
              :lableText="'Email'"
              @blurAction="checkEmail"
              @keyPressAction="errObj.emailErr = ''"
              :autoFocus="false"
              :fieldError="errObj.emailErr !== ''"
              @inputChangeAction="(data) => sendObj.emailAddress = data"
              />
              <p class="text-error heading-7">{{errObj.emailErr}}</p>
            </div>
            <div class="">
              <div class="mb-4">
                <NumberInput
                :inputId="'pinText'"
                :textMaxlength="4"
                :inputext="sendObj.pin"
                :placholderText="`Pin`"
                :inputType="'text'"
                :textRight="false"
                :lableText="'Login Pin (4 Digit, used to login)'"
                :fieldError="errObj.newPinErr !== ''"
                :autoFocus="false"
                @keyPressAction="errObj.newPinErr = ''"
                @blurAction="sendObj.pin.length >  0 ? errObj.newPinErr ='' : null "
                @inputChangeAction="(data) => sendObj.pin = data" />
                <p v-if="errObj.newPinErr !== ''" class="text-error heading-7">{{errObj.newPinErr}}</p>
              </div>
            </div>
          </div>
          <div class="sm:grid grid-cols-2 gap-4 mt-2">
            <div class="mb-4 cursor-pointer" @click="clickOnTime()">
              <p class="label_css">Time Zone</p>
                 <div type="text" @click="clickOnTime()"
                 class="flex items-center cursor-pointer outline-none border border-gray2 text-text1 rounded cust_text_field w-full h-14 pl-2 p-2.5 focus:border-primary focus:ring-1 focus:ring-primary" 
                 placeholder="Time Zone">{{ sendObj.timeZone }}</div>
            </div>
            <div class=" flex items-center">
              <p class="text-gray3 pr-4">Display Color</p>
              <button @click="showColorPicker = true" style="height: 28px; width:40px" :style="`background: ${sendObj.displayColor}`"  class="card border rounded"></button>
            </div>
          </div>
          <div class="cursor-pointer text-primary -mt-2" @click="setTimeDevice()">Set Device Time Zone</div>
        </div>
        <div class="flex card rounded-xl bg-white p-4 my-4 ">
          <div class="mt-2 min-w-full">
            <div class="flex items-center">
              <label class="cu_switch  mr-3" :class="`${!permissionObj.isAdmin ? 'ml-2' : ''}  ${!activeUer.isOwner ? '' : 'cursor-pointer'}`">
                <input :disabled="!activeUer.isOwner" type="checkbox" v-model="permissionObj.isAdmin">
                <span class="cu_slider cu_round"></span>
              </label>
              <p class="text-gray3 cursor-pointer" @click="permissionObj.isAdmin=!permissionObj.isAdmin">Admin</p>
            </div>
            <p class="text-gray3 mb-4 mt-1">Admin user will have full access but cannot create new admin user also admin user will not be able to view or disable owner login</p>
              <p class="text-text2 heading-4">Shift</p>
              <div class="flex items-center mb-2 pt-1">
                <label class="cu_switch cursor-pointer mr-3" :class="!permissionObj.selfieRequiredForShift ? 'ml-2' : ''">
                  <input type="checkbox" v-model="permissionObj.selfieRequiredForShift">
                  <span class="cu_slider cu_round"></span>
                </label>
                <p class="text-gray3 cursor-pointer" @click="permissionObj.selfieRequiredForShift=!permissionObj.selfieRequiredForShift">Clock in/Out Selfie is required</p>
              </div>
              <div class="flex items-center">
                <label class="cu_switch cursor-pointer mr-3" :class="!permissionObj.locationTrackingRequiredForShift ? 'ml-2' : ''">
                  <input type="checkbox" v-model="permissionObj.locationTrackingRequiredForShift">
                  <span class="cu_slider cu_round"></span>
                </label>
                <p class="text-gray3 cursor-pointer" @click="permissionObj.locationTrackingRequiredForShift=!permissionObj.locationTrackingRequiredForShift">Track location for ongoing shift</p>
              </div>
              <p class="text-text2 heading-4 mb-2 mt-4">Visit</p>
              <div class="flex items-center mb-2 pt-1">
                <label class="cu_switch cursor-pointer mr-3" :class="!permissionObj.selfieRequiredForJob ? 'ml-2' : ''">
                  <input type="checkbox" v-model="permissionObj.selfieRequiredForJob">
                  <span class="cu_slider cu_round"></span>
                </label>
                <p class="text-gray3 cursor-pointer" @click="permissionObj.selfieRequiredForJob = !permissionObj.selfieRequiredForJob">Visit start/end Selfie is required</p>
              </div>
              <div class="flex items-center">
                <label class="cu_switch cursor-pointer mr-3" :class="!permissionObj.locationTrackingRequiredForJob ? 'ml-2' : ''">
                  <input type="checkbox" v-model="permissionObj.locationTrackingRequiredForJob">
                  <span class="cu_slider cu_round"></span>
                </label>
                <p class="text-gray3 cursor-pointer" @click="permissionObj.locationTrackingRequiredForJob = !permissionObj.locationTrackingRequiredForJob">Track location while visit is running</p>
              </div>
              <!-- <div class="flex items-center pt-4">
                <label class="cu_switch mr-3 cursor-pointer" :class="`${!permissionObj.isActive ? 'ml-2' : ''}  `">
                  <input type="checkbox" v-model="permissionObj.isActive">
                  <span class="cu_slider cu_round"></span>
                </label>
                <p class="text-gray3 pl-2">{{permissionObj.isActive ? 'Employee is Active' : 'Employee is InActive'}}</p>
              </div> -->
            </div>
        </div>
        <div class="flex justify-end  card rounded-xl bg-white p-4 my-4">
          <div class="text-rigth flex gap-2">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'"
              @buttonAction="discard()" />
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'"
              @buttonAction="updateDetails()" />
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay relative px-4" v-if="showColorPicker">
       <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div class="text-center">
            <p class="text-gray4">Select Color Code</p>
        </div>
        <p class="divider"></p>
        <div class="flex flex-col items-center justify-center">
          <div class="grid w-full max-w-xl grid-cols-12 mx-auto m-4">
            <div v-for="(color, index) in colorList" class="p-2 " :key="index">
              <span @click="addedColor(color)" :class="color.isSelected ? 'border border-text1 -translate-y-2 scale-125 transform transition' : 'border border-gray2'" class="text-text2 card rounded cursor-pointer h-8 w-8 flex" :style="'background-color:' + color.code"></span>
            </div>
          </div>
        </div>
        <p class="divider"></p>
        <div class="flex gap-2 justify-end mt-4">
          <Button :btnSize="'small'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="showColorPicker = false"/>
        </div>
      </div>
    </div>
    <div v-if="openTimePopup" class="popup_overlay px-4">
      <div class="custom_dialog rounded-xl" style="width: 600px; max-height: 85%;">
        <div class="bg-primary flex p-3 text-center justify-center sticky top-0">
          <div class=" font-medium text-white heading-3">
            Select Time Zone
          </div>
        </div>
        <div style="max-height: 60vh" class="overflow-auto">
          <div class="p-2 px-4">
            <div v-for="(data, index) in timeZoneList" :key="index">
              <div class="flex items-center py-2 my-2" :id="data.value">
                <input type="radio" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" v-model="radioModel" name="radio1" :value="data.value" @click="clickOnRadio(data)">
                <div class="pl-3">
                  <div class="flex text-text2 "><p>{{data.text}}</p></div>
                  <div class="flex text-gray3 "><p>{{data.value}}</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
          <div class="text-rigth flex gap-2 mb-3">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="closetime(false)"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="closetime(true)"/>
          </div>
        </div>
      </div>
    </div>
    <GlobalOtpVerify v-if="showOtpPopup && tokenGenerated !== ''" :verifyEmail="false" :emailAddress="custDetails.emailAddress" :otptoken="tokenGenerated" />
  </div>
</template>
<script>
import timeList from '@/assets/timeZone.json'
import { validateEmail, checkSpeialChar } from "@/utils/validation.js";
import NumberInput from '@/View/components/number-text-field.vue'
import TextField from '@/View/components/textfield.vue'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from "@/api/MyJobApp.js";
import GlobalOtpVerify from '../../components/globalOtpVerify.vue';
export default {
  name: "customer",
  components: {
    Button,
    NumberInput,
    TextField,
    GlobalOtpVerify
  },
  data() {
    return {
      selectedTimeZone: {},
      showOtpPopup: false,
      tokenGenerated: '',
      pinTextBox: false,
      showColorPicker: false,
      errObj: {
        firstNameErr: '',
        newPinErr: '',
        phoneErr: '',
        emailErr: '',
        userNameErr: ''
      },
      activeUer: {},
      colorList: [
        {code: '#7289DA', isSelected: false},
        {code: '#FF7200', isSelected: false},
        {code: '#0e7484', isSelected: false},
        {code: '#E91E63', isSelected: false},
        {code: '#1AB426', isSelected: false},
        {code: '#F08080', isSelected: false},
        {code: '#FFC0CB', isSelected: false},
        {code: '#FF1493', isSelected: false},
        {code: '#FF6347', isSelected: false},
        {code: '#FFD700', isSelected: false},
        {code: '#FFFACD', isSelected: false},
        {code: '#FFE4B5', isSelected: false},
        {code: '#4B0082', isSelected: false},
        {code: '#2E8B57', isSelected: false},
        {code: '#7FFF00', isSelected: false},
        {code: '#00FFFF', isSelected: false},
        {code: '#4682B4', isSelected: false},
        {code: '#8d2345', isSelected: false},
        {code: '#008080', isSelected: false},
        {code: '#191970', isSelected: false},
        {code: '#D2691E', isSelected: false},
        {code: '#1976D6', isSelected: false},
        {code: '#409F26', isSelected: false},
        {code: '#C8E6FD', isSelected: false},
        {code: '#2D2D2D', isSelected: false},
        {code: '#949494', isSelected: false},
        {code: '#3f38ef', isSelected: false},
        {code: '#79FDF9', isSelected: false},
        {code: '#FFFFFF', isSelected: false},
        {code: '#000000', isSelected: false},
        {code: '#38B0DE', isSelected: false},
        {code: '#F2473F', isSelected: false},
        {code: '#b400ff', isSelected: false},
        {code: '#6600FF', isSelected: false},
        {code: '#3A5894', isSelected: false},
        {code: '#2F2F4F', isSelected: false},
        {code: '#174038', isSelected: false},
        {code: '#fffa00', isSelected: false},
        {code: '#c39e0a', isSelected: false},
        {code: '#9dcff9', isSelected: false},
        {code: '#E0F12E', isSelected: false},
        {code: '#69808F', isSelected: false},
        {code: '#CD1F2D', isSelected: false},
        {code: '#B85E68', isSelected: false},
        {code: '#000152', isSelected: false},
        {code: '#a9a9de', isSelected: false},
        {code: '#1295BA', isSelected: false},
        {code: '#ff4000', isSelected: false},
      ],
      radioModel: '',
      openTimePopup: false,
      sendObj: {
        timeZone: '',
        userDetailId: 0,
        firstName: '',
        lastName: '',
        userName:'' ,
        emailAddress:'' ,
        isApproved: false,
        approvedOn: '',
        pin: '',
        displayColor: '',
        languageCode: '',
      },
      permissionObj: {
        userDetailId: 0,
        alternateUserId: 0,
        isActive: true,
        isAdmin: false,
        locationTrackingRequiredForShift: true,
        locationTrackingRequiredForJob: true,
        selfieRequiredForShift: false,
        selfieRequiredForJob: false,
      },
      custDetails: null,
      type: 'hex',
      hex: '',
    };
  },
  created() {},
  computed: {
    color: {
      get () {
        return this.sendObj.displayColor
      },
      set (v) {
        this[this.type] = v
      }
    }
  },
  mounted() {
    this.timeZoneList = timeList
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo'))
    this.setTimeDevice()

  },
  watch: {
  },
  methods: {
    closetime (value) {
      if (value) {
        this.sendObj.timeZone = this.selectedTimeZone.value
        this.openTimePopup = false
      } else {
        this.openTimePopup = false
      }
    },
    clickOnRadio (data) {
      this.selectedTimeZone = data
    },
    clickOnTime () {
      this.radioModel = this.sendObj.timeZone
      this.openTimePopup = true
      setTimeout(() => {
        document.getElementById(this.sendObj.timeZone).scrollIntoView({behavior: "smooth",block: 'center'})
      }, 170);
    },
    setTimeDevice () {
    console.log('timeZone-------------------->', Intl.DateTimeFormat().resolvedOptions().timeZone)
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (timeZone === 'Asia/Calcutta') {
        this.sendObj.timeZone = 'Asia/Kolkata'
      } else {
        this.sendObj.timeZone = timeZone
      }
    },
    addedColor(data) {
      for (let index = 0; index < this.colorList.length; index++) {
        if (this.colorList[index].code === data.code) {
          this.colorList[index].isSelected = true
          this.sendObj.displayColor = data.code
        } else {
          this.colorList[index].isSelected = false
        }    
        this.showColorPicker = false
      }
      data.isSelected = true
    },
    checkContactNumber () {
      let value = checkSpeialChar(this.sendObj.cellNumber)
      console.log('value', value)
      if (value) {
        this.errObj.phoneErr = 'Please enter valid contact number'
      } else {
        this.errObj.phoneErr = ''
      }
      // if (this.sendObj.cellNumber.length < 10 && this.sendObj.cellNumber !== '') {
      //     this.errObj.phoneErr = 'Please enter valid contact number'
      // } else if (this.sendObj.cellNumber.length === 10 && this.sendObj.cellNumber.trim() !== '' || this.sendObj.cellNumber.trim() === '') {
      //     this.errObj.phoneErr = ''
      // }
    },
    checkEmail () {
      if (this.sendObj.emailAddress !== '' && validateEmail(this.sendObj.emailAddress) === false) {
          this.errObj.emailErr = "Please enter valid email";
          return;
      } else {
        this.errObj.emailErr = "";
      }
    },
    checkuserName () {
      this.useNameExist = null
      if (this.sendObj.userName.indexOf('@') > -1) {
        this.errObj.userNameErr = "@ not allowed in username"
      } else {
        this.errObj.userNameErr = ""
      }
    },
    updateDetails () {
      let isValid = false
      if (this.sendObj.firstName.trim() === '' ) {
        this.errObj.firstNameErr = 'First Name is Required'
        isValid = false
      }
      if (this.sendObj.userName.trim() === '' ) {
        isValid = false
         this.errObj.userNameErr = 'Username is Required'
      }
      // if (this.sendObj.emailAddress === '' ) {
      //   this.errObj.emailErr = 'Email Address is Required'
      //   isValid = false
      // }
      if (this.sendObj.pin.trim() === '' ) {
        this.errObj.newPinErr = 'Pin is Required'
        isValid = false
      }
      // this.checkContactNumber()
      if (this.errObj.firstNameErr === '' && this.errObj.userNameErr === '' && this.errObj.newPinErr === '' && this.errObj.emailErr === '' && this.errObj.phoneErr === '') {
        isValid = true
      }
      if (isValid) {
        this.updateEmployee()
      }
    },
    updateContact (detail) {
     console.log('detail', detail);
    },
    verifyUserName () {
      if (this.sendObj.userName.indexOf('@') > -1) {
        this.errObj.userNameErr = "@ not allowed in username"
      } else {
        MyJobApp.checkUserNameExistWeb(
          this.sendObj.userDetailId,
          this.sendObj.userName,
          response => {
            console.log('response', response)
          },
          (error) => {
            this.errObj.userNameErr = "User name already exist"
             console.log('error', error)
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          }
        )
      }
    },
    updatePermision (data) {
      this.permissionObj.userDetailId = data
      this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
        MyJobApp.AddNewPermission(
          this.permissionObj,
          response => {
            this.$router.push({ name: 'SettingEmployeesDetail', params: { empId: data }, query: {showDelele: false}})
            this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          },
          (error) => {
            this.$store.dispatch('SetAlert', { showAlert: true, message: error.message, color: 'error' })
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          }
        )
    },
    updateEmployee () {
      this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
        MyJobApp.AddNewEmployee(
          this.sendObj,
          response => {
            console.log('response', response)
            this.updatePermision(response.Data)
            this.$store.dispatch('SetAlert', { showAlert: true, message: response.message, color: 'success' })
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          },
          (error) => {
            this.$store.dispatch('SetAlert', { showAlert: true, message: error.message, color: 'error' })
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          }
        )
    },
    discard () {
      this.$router.push({name: 'SettingEmployees'})
    },
  },
  beforeDestroy() {
    this.$root.$off("setNewTabActive");
    this.$root.$off("addContactHandler");
    this.$root.$off("globalOtpHandler");
  },
};
</script>
<style scoped>
</style>
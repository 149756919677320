<template>
  <div>
    <div class="popup_overlay relative px-4">
      <div style="width: 490px" class="custom_dialog rounded-xl h-min">
        <div v-if="!changeEmail" 
          class="border text-center border-gray-200 px-4 md:px-8 py-8 rounded-lg shadow-2xl"
        >
          <p class="heading-3">
            Please enter 6 digit OTP sent to
            <span class="font-semibold">{{ userEmail }}</span>
          </p>
          <p v-if="verifyEmail" class="heading-4">
            In case you haven't recived email yet, please check your Spam folder
          </p>
          <div class="my-4 flex justify-center">
            <div class="w-56">
              <TextField
                :textMaxlength="6"
                :isOtp="true"
                :fieldError="otpErr !== ''"
                @keyPressAction="otpErr = ''"
                :inputId="`otpinputvalueid`"
                :inputext="otpValue"
                :placholderText="``"
                :lableText="''"
                :autoFocus="true"
                :inputType="'text'"
                @inputChangeAction="(data) => (otpValue = data)"
              />
            </div>
          </div>
          <div :class="`flex  ${verifyEmail ? 'justify-between' : 'justify-center'} mb-4`">
            <div v-if="verifyEmail" @click="changeEmail = true" class="cursor-pointer text-primary">
              Change Email
            </div>
            <div v-if="countDown > 0" class="">
              Resend OTP <span>in {{ countDown }} Sec</span>
            </div>
            <div v-else @click="reSendOtp" class="cursor-pointer text-primary">
              Resend OTP
            </div>
          </div>
          <div class="py-2 flex gap-4 justify-between">
            <Button 
              class="h-10 w-full"
              :btnSize="'medium'"
              :textColor="'white'"
              :btnColor="'gray3'"
              :btnText="'Cancel'"
              @buttonAction="cancelVerify()"
            />
            <Button
              :disabled="otpValue === ''"
              class="h-10 w-full"
              :btnSize="'medium'"
              :textColor="'white'"
              :btnColor="'primary'"
              :btnText="'Verify OTP'"
              @buttonAction="verifyOtp()"
            />
          </div>
        </div>
        <div v-else class="border border-gray-200 px-4 md:px-8 py-8 rounded-lg shadow-2xl">
          <p class="heading-2">Change Email</p>
          <div class="divider mb-4"></div>

                <div class="mb-4 w-full">
                   <TextField
                    :inputId="`useremailid1`"
                    :inputext="userEmail"
                    :placholderText="`Email Address`"
                    :lableText="'Email Address'"
                    :fieldError="emailErr !== ''"
                    :autoFocus="true"
                    :inputType="'text'"
                    @keyPressAction="emailErr = ''"
                    @enterkeyPressAction="openPlanDetail"
                    @blurAction="emailHandler(userEmail)"
                    @inputChangeAction="(data) => userEmail = data"  />
                    
                    <p class="text-error"> {{ emailErr }}</p>
                </div>

            <div class="py-2 flex gap-4 justify-between">
            <Button 
              class="h-10 w-full"
              :btnSize="'medium'"
              :textColor="'white'"
              :btnColor="'gray3'"
              :btnText="'Close'"
              @buttonAction="changeEmail=false; userEmail= emailAddress"
            />
            <Button
              :disabled="emailErr !== '' || userEmail === emailAddress"
              class="h-10 w-full"
              :btnSize="'medium'"
              :textColor="'white'"
              :btnColor="'primary'"
              :btnText="'Update'"
              @buttonAction="updateEmail()"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyJobApp from "@/api/MyJobApp.js";
import Button from "@/View/components/globalButton.vue";
import TextField from "@/View/components/textfield.vue";
import {validateEmail} from '@/utils/validation.js'
export default {
  name: "lead",
  components: {
    Button,
    TextField,
  },
  data() {
    return {
      emailErr: '',
      changeEmail: false,
      Otptoken: "",
      countDown: 30,
      otpErr: "",
      otpValue: "",
      userEmail: "",
    };
  },
  props: ["emailAddress", "otptoken", "verifyEmail"],
  created() {},
  mounted() {
    console.log("otptoken", this.otptoken);
    this.userEmail = this.emailAddress;
    this.Otptoken = this.otptoken;
    this.countDownTimer();
  },
  destroyed() {
    // this.$root.$off("changeEmailHandler");
  },
  beforeDestroy() {},
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    reSendOtp() {
      this.$store.dispatch("SetLoader", {
        status: true,
        message: "Resending...",
      });
      MyJobApp.reSendEndUserOtp(
        this.Otptoken,
        (response) => {
          this.countDown = 30;
          this.countDownTimer();
          this.$store.dispatch("SetAlert", {
            showAlert: true,
            message: response.message,
            color: "success",
          });
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (error) => {
          this.$store.dispatch("SetAlert", {
            showAlert: true,
            message: error.message,
            color: "error",
          });
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    cancelVerify() {
      this.$root.$emit("globalOtpHandler", false);
    },
    verifyOtp() {
         this.$store.dispatch("SetLoader", {
        status: true,
        message: "Sending...",
      });
      MyJobApp.otpVarify(
        this.Otptoken,
        this.otpValue,
        (response) => {
          console.log("response -------->", response);
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.$store.dispatch("SetAlert", {
            showAlert: true,
            message: "OTP verified successfully",
            color: "success",
          });
          setTimeout(() => {
            this.$root.$emit("globalOtpHandler", true);
          }, 500);
        },
        (error) => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.$store.dispatch("SetAlert", {
            showAlert: true,
            message: error.message,
            color: "error",
          });
        }
      );
    },
    updateEmail() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading..."});
      MyJobApp.ChangeUserEmail(
        this.userEmail,
        (response) => {
          this.Otptoken = response.Data
          this.changeEmail = false
          console.log("response -------->", response);
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.$store.dispatch("SetAlert", {   showAlert: true,   message: "Updated successfully",   color: "success"});
          this.$root.$emit("globalOtpHandler", null);
          this.countDown = 30;
          this.countDownTimer();
        },
        (error) => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.$store.dispatch("SetAlert", {
            showAlert: true,
            message: error.message,
            color: "error",
          });
        }
      );
    },
    emailHandler (emailId) {
      this.emailErr = this.emailValidation(emailId)
    },
    emailValidation (emailId) {
      if (emailId === '') {
        return 'Email address is required'
      } else if (validateEmail(emailId) === false) {
        return 'Email address is invalid'
      } else {
        return ''
      }
    },
  },
};
</script>
<style scoped>
</style>